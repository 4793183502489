<template>
  <div>
    <v-btn @click="showMessages">show messages</v-btn>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import {ref, reactive, watch, computed} from 'vue'
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  page: {
    title: 'Test Stuff',
  },
  //model: {
  //	prop: ''
  //},
  props: {},
  components: {},
  filters: {},
  data() {
    return {
      items: [],
    }
  },
  // setup(props, context) {
  //   console.devlog('setup')
  //   var items = ref([])
  //   for (var i = 0; i < 2000000; i++) {
  //     items.value.push({id: i + 1})
  //   }
  //   var find = 1999999
  //   var findString = '1999999'
  //   setTimeout(function () {
  //     console.pink('for loop find === index')
  //     var tempList = items.value
  //     var index = -1
  //     for (var i = 0; i < tempList.length; i++) {
  //       if (tempList[i].id === find) {
  //         index = i
  //         break
  //       }
  //     }
  //     console.pink('for loop find index done', index)
  //     console.ltBlue('for loop find == int index')
  //     var tempList1 = items.value
  //     var index1 = -1
  //     for (var i = 0; i < tempList1.length; i++) {
  //       if (tempList1[i].id == find) {
  //         index = i
  //         break
  //       }
  //     }
  //     console.ltBlue('for loop find index done', index1)
  //     console.blue('for loop find  == parseInt(int) index')
  //     var tempList10 = items.value
  //     var index10 = -1
  //     var value = parseInt(find)
  //     for (var i = 0; i < tempList10.length; i++) {
  //       if (tempList10[i].id == value) {
  //         index = i
  //         break
  //       }
  //     }
  //     console.blue('for loop find index done', index10)
  //     console.cyan('for loop find == parseInt(string) string index')
  //     var tempList11 = items.value
  //     var index11 = -1
  //     var value = parseInt(findString)
  //     for (var i = 0; i < tempList11.length; i++) {
  //       if (tempList11[i].id == value) {
  //         index = i
  //         break
  //       }
  //     }
  //     console.cyan('for loop find convert string index done', index11)
  //     console.green(
  //       'for loop find convert == parstInt in for loop string index'
  //     )
  //     var tempList12 = items.value
  //     var index12 = -1
  //     for (var i = 0; i < tempList12.length; i++) {
  //       if (tempList12[i].id == parseInt(findString)) {
  //         index = i
  //         break
  //       }
  //     }
  //     console.green('for loop find convert string index done', index12)
  //     console.purple('for loop find index == string by string')
  //     var tempList2 = items.value
  //     var index2 = -1
  //     for (var i = 0; i < tempList2.length; i++) {
  //       if (tempList2[i].id == findString) {
  //         index1 = i
  //         break
  //       }
  //     }
  //     console.purple('for loop find index by string done', index2)
  //     console.magenta('findIndex')
  //     var index3 = items.value.findIndex((item) => item.id === find)
  //     console.magenta('findIndex done', index3)
  //     console.blue('findIndex')
  //     var tempList4 = items.value
  //     var index4 = tempList4.findIndex((item) => item.id === find)
  //     console.blue('findIndex done', index4)
  //   }, 2000)
  //   return {
  //     compItems: items,
  //   }
  // },
  created() {
    // var items = this.items
    // var compItems = this.compItems
    // for (var i = 0; i < 1000000; i++) {
    //   items.push({id: i + 1})
    // }
    // var find = 999999
    // console.magenta('for loop find')
    // var item = null
    // for (var i = 0; i < items.length; i++) {
    //   if (items[i].id === find) {
    //     item = items[i]
    //     break
    //   }
    // }
    // console.magenta('for loop find done')
    // console.purple('find')
    // var item2 = items.find((item) => item.id === find)
    // console.purple('find done')
    // console.pink('for loop comp find')
    // var item3 = null
    // for (var i = 0; i < compItems.length; i++) {
    //   if (compItems[i].id === find) {
    //     item3 = compItems[i]
    //     break
    //   }
    // }
    // console.pink('for loop comp find done')
    // console.blue('comp find')
    // var item4 = compItems.find((item) => item.id === find)
    // console.blue('comp find done')
    // console.magenta('start date time comparison')
    // for (var i = 0; i < 1000000; i++) {
    //   new Date().getTime() < new Date('2021-05-23').getTime()
    // }
    // console.magenta('end date time comparison')
    // console.pink('start  date comparison')
    // for (var i = 0; i < 1000000; i++) {
    //   new Date() < new Date('2021-05-23')
    // }
    // console.pink('end date comparison')
    // console.purple('start dayjs comparison')
    // for (var i = 0; i < 1000000; i++) {
    //   dayjs() < dayjs('2021-05-23')
    // }
    // console.purple('end dayjs comparison')
    // console.magenta('start date time valid')
    // for (var i = 0; i < 1000000; i++) {
    //   new Date('2021-05-23').getTime() === new Date('2021-05-23').getTime()
    // }
    // console.magenta('end date time valid')
    // console.purple('start dayjs valid')
    // for (var i = 0; i < 1000000; i++) {
    //   dayjs().isValid()
    // }
    // console.purple('end dayjs valid')
    // console.magenta('start date time comparison and validation')
    // for (var i = 0; i < 1000000; i++) {
    //   new Date() < new Date('2021-05-23')
    //   new Date('2021-05-23').getTime() === new Date('2021-05-23').getTime()
    // }
    // console.magenta('end date time comparison and validation')
    // console.purple('start date variable comparison and validation')
    // for (var i = 0; i < 1000000; i++) {
    //   let date = new Date('2021-05-23')
    //   new Date() < date
    //   date.getTime() === date.getTime()
    // }
    // console.purple('end date varaiable comparison and validation')
    // console.blue('start date time variable comparison and validation')
    // for (var i = 0; i < 1000000; i++) {
    //   let date = new Date('2021-05-23').getTime()
    //   new Date().getTime() < date
    //   date === date
    // }
    // console.blue('end date time varaiable comparison and validation')
  },

  computed: {},
  methods: {
    dialogMessage: call('messages/dialogMessage'),
    showMessages() {
      let messages = [
        'For strata test stratum no projections will be entered. Reason: Less than 3 active entries in Exceptions screen for stratum.',
        'For strata test stratum 2 no projections will be entered. Reason: Less than 3 active entries in Exceptions screen for stratum.',
        'For strata test stratum 3 no projections will be entered. Reason: Less than 3 active entries in Exceptions screen for stratum.',
        'For strata test stratum 4 no projections will be entered. Reason: Less than 3 active entries in Exceptions screen for stratum.',
        'For strata test stratum 5 no projections will be entered. Reason: Less than 3 active entries in Exceptions screen for stratum.',
      ]
      this.dialogMessage({message: messages})
    },
  },
  watch: {},
}
</script>
<style scoped></style>
